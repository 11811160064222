import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { Modal, ModalService } from 'src/app/project/components/modal/modal.service';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { getErrorMessage } from 'src/app/project/helpers/database/get-error-message';
import { TWorkspacesById } from '../../workspace/workspace.model';
import { WorkspaceService } from '../../workspace/workspace.service';
import { TDeleteSiteModalData } from './delete-site-modal.model';
import { DeleteSiteService } from './delete-site.service';

@Component({
  selector: 'pp-delete-site-modal',
  templateUrl: './delete-site-modal.component.html',
  styleUrls: ['./delete-site-modal.component.scss'],
})
export class DeleteSiteModalComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  private modal: Modal;

  processing = false;

  deleteSiteMessage = '';

  siteName: string;
  workspaceId: string;
  workspaces: TWorkspacesById;

  constructor(
    private promptService: PromptService,
    private modalService: ModalService,
    private translationPipe: TranslationPipe,
    private workspaceService: WorkspaceService,
    private deleteSiteService: DeleteSiteService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.modal = this.modalService.getModal();
    this.workspaces = this.workspaceService.getWorkspaces();

    const modalData: TDeleteSiteModalData = this.modal.data;

    this.workspaceId = modalData.workspaceId;
    this.siteName = this.workspaces[this.workspaceId].siteName;
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  hideModal(): void {
    this.modalService.hideModal();
  }

  deleteSite(): void {
    if (this.processing) {
      return;
    }

    this.processing = true;

    this.deleteSiteService
      .deleteSite(this.workspaceId)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          const prompt = this.translationPipe.transform('site_deleted');

          this.promptService.showSuccess(prompt);

          this.router.navigate(['/dashboard']);
          this.hideModal();
        }),
        catchError((error) => {
          switch (error.status) {
            case EStatusCode.FORBIDDEN: {
              getErrorMessage(error).then((message) => {
                this.promptService.showError(message);
              });
              break;
            }
            default:
              const prompt = this.translationPipe.transform('prompt_error');

              this.promptService.showError(prompt);
              break;
          }

          this.processing = false;

          throw error;
        }),
      )
      .subscribe();
  }
}
